import React, { createContext, useContext } from 'react';

// images for Ryanair Presentation
import img1 from "../images/Ryanair/ryanair_presentation/r1.jpg";
import img2 from "../images/Ryanair/ryanair_presentation/r2.jpg";
import img3 from "../images/Ryanair/ryanair_presentation/r3.jpg";
import img4 from "../images/Ryanair/ryanair_presentation/r4.jpg";
import img5 from "../images/Ryanair/ryanair_presentation/r5.jpg";
import img6 from "../images/Ryanair/ryanair_presentation/r6.jpg";
import img7 from "../images/Ryanair/ryanair_presentation/r7.jpg";
import img8 from "../images/Ryanair/ryanair_presentation/r8.jpg";
import img9 from "../images/Ryanair/ryanair_presentation/r9.jpg";
import img10 from "../images/Ryanair/ryanair_presentation/r10.jpg";
import img11 from "../images/Ryanair/ryanair_presentation/r11.jpg";
import img12 from "../images/Ryanair/ryanair_presentation/r12.jpg";
import img13 from "../images/Ryanair/ryanair_presentation/r13.jpg";
import img14 from "../images/Ryanair/ryanair_presentation/r14.jpg";
import img15 from "../images/Ryanair/ryanair_presentation/r15.jpg";
import img16 from "../images/Ryanair/ryanair_presentation/r16.jpg";
import img17 from "../images/Ryanair/ryanair_presentation/r17.jpg";
import img18 from "../images/Ryanair/ryanair_presentation/r18.jpg";
import img19 from "../images/Ryanair/ryanair_presentation/r19.jpg";
import img20 from "../images/Ryanair/ryanair_presentation/r20.jpg";
import img21 from "../images/Ryanair/ryanair_presentation/r21.jpg";

// images for Ryanair BTS
import img22 from "../images/Ryanair/bts/card_finalslide.jpg";
import img23 from "../images/Ryanair/bts/designing_colors.jpg";
import img24 from "../images/Ryanair/bts/first_sketch.jpg";
import img25 from "../images/Ryanair/bts/layout_test.JPG";
import img26 from "../images/Ryanair/bts/notes_sketch.JPG";
import img27 from "../images/Ryanair/bts/ryanair_draftlayout.JPG";
import img28 from "../images/Ryanair/bts/second_sketch_empty.JPG";
import img29 from "../images/Ryanair/bts/workplace.png";
import img30 from "../images/Ryanair/bts/reenacting.jpg";

// images for Viktoriabad BTS
import img31 from "../images/Viktoriabad/großesBad_hinten.jpeg";
import img32 from "../images/Viktoriabad/großesBad_vorne.jpeg";
import img33 from "../images/Viktoriabad/kleinesBadHinten.jpeg";
import img34 from "../images/Viktoriabad/kleinesBadSeite.jpeg";
import img35 from "../images/Viktoriabad/kleinesBadVorne.jpeg";
import img36 from "../images/Viktoriabad/kleinZuGroß.jpeg";
import img37 from "../images/Viktoriabad/spinde.jpeg";
import img38 from "../images/Viktoriabad/spinde2.jpeg";
import img39 from "../images/Viktoriabad/render4_club.png";
import imgOffice from "../images/Viktoriabad/office_render.jpg";
import imgProgramm from "../images/Viktoriabad/example_programm_victoria.png";
import imgBar from "../images/Viktoriabad/main_render.png";

// images for aachen
import img40 from "../images/Aachen/framed_1.png";
import img40A from "../images/Aachen/overview4.png";
import img41 from "../images/Aachen/framed_2.png";
import img41A from "../images/Aachen/sketches_aachen.jpg";
import img42 from "../images/Aachen/framed_3.png";
import img42A from "../images/Aachen/inspo_aachen.png";
import img43 from "../images/Aachen/framed_4.png";
import img43A from "../images/Aachen/current_aachen.jpg";

// images for rolef
import img44 from "../images/rolef_render.png";
import imgSlide1 from "../images/Rolef/rolef_slide1.jpg";
import imgSlide2 from "../images/Rolef/rolef_slide2.jpg";
import imgSlide3 from "../images/Rolef/rolef_slide3.jpg";
import imgSlide4 from "../images/Rolef/rolef_slide4.jpg";
import imgSlide5 from "../images/Rolef/rolef_slide5.jpg";
import imgSlide6 from "../images/Rolef/rolef_slide6.jpg";
import imgSlide7 from "../images/Rolef/rolef_slide7.jpg";
import imgSlide8 from "../images/Rolef/rolef_slide8.jpg";
import imgSlide9 from "../images/Rolef/rolef_slide9.jpg";
import imgSlide10 from "../images/Rolef/rolef_slide10.jpg";
import imgSlide11 from "../images/Rolef/rolef_slide11.jpg";
import imgSlide12 from "../images/Rolef/rolef_slide12.jpg";
import imgSlide13 from "../images/Rolef/rolef_slide13.jpg";
import imgwebsite from "../images/Rolef/screenshot_reparatur.png";


// images for elgio
import img46 from "../images/Elgio/big_banner.png";
import webapp1 from "../images/Elgio/products/article_list.png";
import webapp2 from "../images/Elgio/products/calender_list.png";
import webapp3 from "../images/Elgio/products/calender_map.png";
import webapp4 from "../images/Elgio/products/checkout.png";
import webapp5 from "../images/Elgio/products/club_overview.png";
import webapp6 from "../images/Elgio/products/event_view.png";
import webapp7 from "../images/Elgio/products/upcoming_events.png";
import dashboard1 from "../images/Elgio/products/ticketing_anlegen.png";
import dashboard2 from "../images/Elgio/products/dashboard_overview.png";
import dashboard3 from "../images/Elgio/products/event_creation.png";
import dashboard4 from "../images/Elgio/products/event_management.png";
import dashboard5 from "../images/Elgio/products/marketing.png";
import dashboard6 from "../images/Elgio/products/profil_overview.png";
import dashboard7 from "../images/Elgio/products/settings.png";
import dashboard8 from "../images/Elgio/products/statistics.png";
import dashboard9 from "../images/Elgio/products/ticketingtab_dashboard.png";


// pdfs
import myPDF from '../pdfs/Ryanair_CardDesign_Presentation_OFF.pdf';
import consultingPitch from "../pdfs/Rolef_Präsi_pdf.pdf";
import renders from "../pdfs/Renders.zip";
import { faArrowDown, faGlobe } from '@fortawesome/free-solid-svg-icons';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const projects = [
        {
            id: "elgio",
            title_short: "ELGIO",
            title_long: "Software products for the event market",
            backgroundIMG: img46,
            sections: [
                {
                    title: "Overview",
                    content: "The idea for our startup, ELGIO, was born from the desire for a centralized platform where users could easily find parties and access all relevant information. When we began working on the concept in January 2020, event information was scattered across various media channels—Instagram, Facebook, print, and more—and was often incomplete or missing entirely. Our initial plan was to create an application to display events, supported by a data scraper that would collect information from targeted websites. However, as we delved deeper into the project, we realized that many organizers were still relying on analog processes, making it challenging for them to provide digital event details. Over the next four years, we developed an extensive platform that allowed users to seamlessly browse and discover events. Simultaneously, we created a comprehensive dashboard for organizers to manage all aspects of their events, including ticketing, marketing, analytics, and more.",
                },
                // {
                //     title: "Pitch Deck",
                //     images: [
                //         { image: img1, subtitle: "Title Slide"},
                //     ],
                // },
                {
                    title: "Building during Covid-19",
                    content: "Unfortunately, we began building ELGIO in January 2020, just three months before the world went into lockdown. Being a first-time founder and witnessing the sudden collapse of our entire industry was a strange and challenging experience. Instead of doubting whether our product would ever be needed again, we quickly adopted the mindset that the desire for events would return stronger than ever—and, with it, the demand for our platform. The industry began to recover slowly, with the first events under strict testing restrictions starting in summer 2021. We launched our product in September 2021 and enjoyed two exciting months of growth before the next lockdown forced the industry to shut down again for another six months.",
                },
                {
                    title: "Product 1: Webapp",
                    content: "The web app is the product designed for users who are browsing events. It allows them to explore events and clubs in every major city across Germany. Users can switch between a list view and a map view, and filter events based on various criteria such as date, genre, and type. Each event, club, and artist is presented with all relevant information in the most appealing and structured way possible.",
                    images: [
                        {image: webapp2, subtitle: "List View of upcoming events"},
                        {image: webapp3, subtitle: "Map View of events"},
                        {image: webapp4, subtitle: "Ticket selection and purchase process"},
                        {image: webapp5, subtitle: "Overview of Clubs"},
                        {image: webapp6, subtitle: "Event Page"},
                        {image: webapp7, subtitle: "Upcoming Events of Clubs Preview"},
                        {image: webapp1, subtitle: "Article Page"},
                    ],
                },
                {
                    title: "Product 2: Dashboard",
                    content: "The dashboard is the product designed for event organizers. It allows organizers to manage their events and handle all core tasks related to them, such as marketing, ticketing, statistics, and more. The dashboard is kept simple, with only five main navigation options and a selected set of functions.",
                    images: [
                        {image: dashboard2, subtitle: "Dashboard Home Page"},
                        {image: dashboard1, subtitle: "Create Ticketing with live overview on the right"},
                        {image: dashboard3, subtitle: "Create an event"},
                        {image: dashboard4, subtitle: "Overview of events and drafts"},
                        {image: dashboard5, subtitle: "Overview of events with marketing enabled"},
                        {image: dashboard6, subtitle: "Profile information overview"},
                        {image: dashboard7, subtitle: "Settings"},
                        {image: dashboard8, subtitle: "Statistics"},
                        {image: dashboard9, subtitle: "Ticketing Section of an event"},
                    ],
                },
                {
                    title: "Learnings about building products",
                    content: "When building a startup, you constantly make mistakes and learn new things. Here are some major learnings we've had: 1) Focus on one thing and do it incredibly well. Instead of being mediocre at everything, strive to be the best at one specific thing. 2) Do not guess what the client wants; talk to them. We often built features and services that completely flopped because we assumed what people needed without actually speaking to the client first. 3) Momentum is a precious thing. If you got it, keep it and try to never loose it.",
                },
                
                {
                    title: "Links & Downloads",
                    elements: [
                        // {
                        //     name: "ELGIO Pitch",
                        //     link: myPDF,
                        //     icon: faArrowDown,
                        // },
                        {
                            name: "Website & Webapp",
                            link: "https://elgio.de",
                            icon: faGlobe,
                        },
                        {
                            name: "Dashboard",
                            link: "https://dashboard.elgio.de",
                            icon: faGlobe,
                        }
                    ],
                },
            ]
        },
        // {
        //     id: "ryanair",
        //     title_short: "Ryanair",
        //     title_long: "Re-design of the Ryanair Airplane Safety Card",
        //     backgroundIMG: img29,
        //     sections: [
        //         {
        //             content: "Disclaimer: This project is self-entitled and is not (yet) connected to Ryanair",
        //         },
        //         {
        //             title: "Overview",
        //             content: "The idea for redesigning the safety card was born when I was on a Ryanair flight to Athens. As one may know, the card is unconventionally a sticker placed at eye level. Therefore, staring at the card becomes inevitable. At that point, the card was already annoying me due to its chaotic layout and numerous visual flaws. So, I began writing down all the things that bothered me, designed a rough new layout, and measured the proportions using the length of my pen. The idea stuck with me, so I called a friend, and together with her, we completely redesigned the card.",
        //         },
        //         {
        //             title: "Presentation",
        //             images: [
        //                 { image: img1, subtitle: "Title Slide"},
        //                 { image: img2, subtitle: "Intro Slide"},
        //                 { image: img3, subtitle: "Visualisation of current issues with the card"},
        //                 { image: img4, subtitle: "Summary of current issues"},
        //                 { image: img5, subtitle: "Three reasons why it is relevant for the airline"},
        //                 { image: img6, subtitle: "Objectives for the new design"},
        //                 { image: img7, subtitle: "Intro Slide Creation Process"},
        //                 { image: img8, subtitle: "Looking at current processes"},
        //                 { image: img9, subtitle: "Breakdown of visual guidance through current card"},
        //                 { image: img10, subtitle: "Visualize how the new layout improves readability"},
        //                 { image: img11, subtitle: "Showcase alignment issues with current card"},
        //                 { image: img12, subtitle: "Visualize how the new layout improves relation between dispictions"},
        //                 { image: img13, subtitle: "Highlight of a new addition to the card"},
        //                 { image: img14, subtitle: "Highlight of an improved element on the card"},
        //                 { image: img15, subtitle: "Highlight of an improved element on the card 2"},
        //                 { image: img16, subtitle: "Final Design Slide"},
        //                 { image: img17, subtitle: "The finished new safety card"},
        //                 { image: img18, subtitle: "Two additional ideas to make the card more unique"},
        //                 { image: img19, subtitle: "Rough cost estimate for implementation"},
        //                 { image: img20, subtitle: "Advantages of the new vs. current design"},
        //                 { image: img21, subtitle: "Final Slide"}
        //             ],
        //         },
        //         {
        //             title: "Design Process",
        //             content: "We started by looking at about 50 different safety cards from other airlines and writing down what we liked and didn’t like. We then broke the card down into all its different depicted processes and checked if they were A) too long, B) unclear, C) repetitive, or D) unnecessary. This allowed us to understand how much space we had (since it’s a one-sided sticker) and what we needed to display within this limited space. Next, we brainstormed various layout options with different gap sizes and tested them for readability. Once we agreed on a layout, we knew exactly the dimensions of each box and which process would be displayed in it. Paula then began sketching each box, while I created a list of useful hints and inspiration from other airlines. Finally, we began coloring the card. Similar to the rest of the design, we aimed to use as few colors as possible. We frequently reviewed the card and reduced each image to its core essentials.",
        //         },
        //         {
        //             title: "Challenges",
        //             content: "Since Ryanair did not commission us, we had no access to additional information or resources. For instance, we had to call the customer service team to get details about the children's vest. We also had to personally model and reenact the people depicted in the illustrations, as Paula found it difficult to get the dimensions right. The man and the woman in the card are essentially Paula's sister and myself.",
        //         },
        //         {
        //             title: "Behind the Scenes",
        //             images: [
        //                 { image: img29, subtitle: "Our collaborative work environment to share progress, notes, ideas and inspiration"},
        //                 { image: img24, subtitle: "Very first sketch on the airplane"},
        //                 { image: img28, subtitle: "First proper layout design with pen and paper"},
        //                 { image: img26, subtitle: "Notes and Markings on current card"},
        //                 { image: img25, subtitle: "First digital layout ideas"},
        //                 { image: img27, subtitle: "Testing our layout by filling it with illustrations from old card"},
        //                 { image: img30, subtitle: "Reenacting positions for easier illustrations"},
        //                 { image: img23, subtitle: "Creating the color palatte"},
        //                 { image: img22, subtitle: "Designing final slide of presentation"},
        //             ],
        //         },
        //         {
        //             title: "Contacting Ryanair",
        //             content: "As our goal was to present the card to Ryanair, we created a pitch deck. The deck includes the basics of a typical pitch (problem, solution, costs, further ideas, advantages) and also explains the creation of the card in detail so that the changes and their reasoning are clear. After discussing the deck with a friend who works at an aerospace consulting agency, he provided feedback and even reached out to Ryanair's CEO on our behalf. Unfortunately, around that time, Boeing's airplanes began reporting increasing issues, including the delayed delivery of 60 Ryanair planes. As a result, the company shifted its focus to managing its reputation and addressing financial challenges, rather than engaging with us. We are still actively reaching out to Ryanair, hoping to pitch our concept.",
        //         },
        //         {
        //             title: "Links",
        //             elements: [
        //                 {
        //                     name: "PDF Presentation",
        //                     link: myPDF,
        //                     icon: faArrowDown,
        //                 },
        //             ],
        //         },
        //     ]
        // },
        {
            id: "rolef",
            title_short: "Rolef",
            title_long: "New Website, store layout and consulting for locksmith",
            backgroundIMG: img44,
            sections: [
                {
                    title: "Overview",
                    content: "With the addition of a new employee, the local locksmith in Bonn wanted to reassess all of its business processes, branding, and store layout, with the goal of improving workflows, attracting more customers, and offering a more curated range of services. To support this, I developed and designed a new website for them, proposed ideas for a redesigned store concept and layout, and provided various solutions to address their existing challenges.",
                },
                {
                    title: "New Website",
                    content: "The main goal of the website was to evolve from being just a virtual business card into a platform that effectively presents the team and the work they do. This involved detailing the various services they offer and showcasing previous projects they’ve completed. Additionally, two new request forms were integrated to reduce the reliance on phone calls for customer inquiries.",
                    images: [
                        { image: imgwebsite, subtitle: "Example: Request form"},
                    ],
                },
                {
                    title: "Shop re-design",
                    content: "The current layout has some flaws, as much of the space is occupied by products that customers don’t directly purchase or show interest in. The new layout should create a more inviting atmosphere and highlight products that customers are genuinely interested in.",
                    images: [
                        { image: img44, subtitle: "Render: Layout store"},
                    ],
                },
                {
                    title: "Problems + Solutions",
                    content: "As I am good friends with one of the employees, I had access to valuable information and spent a considerable amount of time understanding the business, its workflows, and areas for improvement.",
                    images: [
                        { image: imgSlide1, subtitle: "Slide 1: Cover"},
                        { image: imgSlide2, subtitle: "Slide 2: Agenda"},
                        { image: imgSlide3, subtitle: "Slide 3: Description"},
                        { image: imgSlide4, subtitle: "Slide 4: Cover Problems"},
                        { image: imgSlide5, subtitle: "Slide 5: Problem 1"},
                        { image: imgSlide6, subtitle: "Slide 6: Problem 2"},
                        { image: imgSlide7, subtitle: "Slide 7: Problem 3"},
                        { image: imgSlide8, subtitle: "Slide 8: Problem 4"},
                        { image: imgSlide9, subtitle: "Slide 9: Problem 5"},
                        { image: imgSlide10, subtitle: "Slide 10: Problem 6"},
                        { image: imgSlide11, subtitle: "Slide 11: Cover further ideas"},
                        { image: imgSlide12, subtitle: "Slide 12: Meeting idea"},
                        { image: imgSlide13, subtitle: "Slide 13: Conclusion"},
                    ],
                },
                {
                    title: "Downloads",
                    elements: [
                        {
                            name: "Consulting Pitch",
                            link: consultingPitch,
                            icon: faArrowDown,
                        },
                    ],
                },
            ]
        },
        {
            id: "viktoriabad",
            title_short: "Viktoriabad",
            title_long: "Concept to re-use an abandoned swimming pool",
            backgroundIMG: img39,
            sections: [
                {
                    title: "Overview",
                    content: "The Viktoriabad is an abandoned indoor swimming pool located in the heart of Bonn. Its distinctive facade, featuring drawings of geysers on stained glass, gives the building a unique character. For the past 10 years, the pool has remained vacant, with only occasional use for minor exhibitions and projects. My vision is to repurpose the space into something the city desperately needs: a cultural hub where young people can gather, broaden their horizons, and enjoy a vibrant club experience.",
                },
                {
                    title: "Current Look",
                    images: [
                        { image: img32, subtitle: "Main Swimming Hall 1/2"},
                        { image: img31, subtitle: "Main Swimming Hall 2/2"},
                        { image: img33, subtitle: "Back view of the learners pool"},
                        { image: img34, subtitle: "Side view of the learners pool"},
                        { image: img35, subtitle: "Front view of the learners pool"},
                        { image: img36, subtitle: "View from learners pool to main hall"},
                        { image: img37, subtitle: "Changing Room entry"},
                        { image: img38, subtitle: "Changing Rooms"},
                    ],
                },
                {
                    title: "Why Nightlife",
                    content: "As Bonn was once the capital of Germany, it boasts a relatively strong infrastructure. The city is home to major companies such as Telekom, DHL, and Haribo, as well as political ministries and a well-reputed university. However, the cultural offerings, especially for younger people, remain limited. Over the past 20 years, only a handful of new restaurants and entertainment venues have opened, while many existing ones continue to close down. Despite a population of 330,000, Bonn is home to only two low-quality nightclubs. The Viktoriabad pool is ideally located directly across from the university, making it the perfect spot for a new venue. I strongly believe the city needs a place where young people and students can come together, as well as a modern nightclub that reflects the vibrant energy of the area.",
                },
                {
                    title: "Concept of the Bar",
                    content: "The core idea is to transform the main pool area into a vibrant cafe, bar, and restaurant. During the day, the space will serve as a 'cafe' that encourages people to come study, enjoy lunch, or simply have a drink. In the evening, the space will transition into a lively 'bar' atmosphere. The adjacent nightclub, visible through frosted glass windows, will invite guests to continue their night there. Throughout the week, the bar will host a variety of cultural events, such as poetry slams, stand-up comedy, movie nights, listening parties, panel talks, and communal drawing sessions. On Sundays, the venue can also host a popular brunch buffet, creating a welcoming space for the community.",
                },
                {
                    images: [
                        { image: imgProgramm, subtitle: "Examplatory Programm Overview"},
                    ],
                },
                {
                    title: "Concept of the Club",
                    content: "The club and concert space will be designed to feel intimate, much like the atmosphere created in music videos. The goal is to seamlessly blend audio and visual elements, enhancing the experience for visitors. To achieve this, the entire room will be surrounded by a 270-degree LED panel, wrapping around the space and creating an immersive environment. The club will operate exclusively on Friday and Saturday nights but can also host concerts throughout the week. Both the bar and the club will share the same kitchen and staff facilities, allowing for more cost-effective operations and greater flexibility. This setup enables staff to easily assist in both spaces, share supplies, and offer specialties like finger food in the club. A unique feature of the venue is the DJ booth, which will be located within the pool, allowing guests to stand 360 degrees around the DJ and fully immerse themselves in the music.",
                },
                {
                    title: "Renderings",
                    images: [
                        { image: img39, subtitle: "Examplatory Renders: The Club"},
                        { image: imgBar, subtitle: "Examplatory Renders: The Bar"},
                        { image: imgOffice, subtitle: "Examplatory Renders: The Office"},
                    ],
                },
                {
                    title: "Moving forward",
                    content: "In an effort to bring this project to life, I spoke with a member of an NGO that is actively working to preserve the swimming pool and prevent it from being demolished. He informed me that the city currently owns the building and plans to hand it over to the region of North Rhine-Westphalia by the end of 2025. The region intends to transform it into a place of thought likely a library. I am currently trying to connect with the city of Bonn to gather more information on the matter. Additionally, I reached out to an architecture firm specializing in these types of projects, and they enthusiastically agreed to help execute my ideas.",
                },
                {
                    title: "Links",
                    elements: [
                        {
                            name: "Rendered Images",
                            link: renders,
                            icon: faArrowDown,
                        },
                    ],
                },
            ]
        },
        {
            id: "oepnv-aachen",
            title_short: "Aachen ÖPNV",
            title_long: "Creation of a more informative Transportation Display",
            backgroundIMG: img40A,
            sections: [
                {
                    title: "Overview",
                    content: "As I was visiting a friend in Aachen, I realized that the digital information screens on public transport were very old and did not display any information. In an attempt to use the available information each bus has (time until the next stop, connections at the stop, etc.), I redesigned the screen to be more informative while remaining simple.",
                },
                {
                    title: "Inspiration & first sketches",
                    images: [
                        { image: img43A, subtitle: "Current Info Display"},
                        { image: img42A, subtitle: "Inspiration of other info displays"},
                        { image: img41A, subtitle: "First sketches on layout"},
                    ],
                },
                {
                    title: "Design Process",
                    content: "At first, I looked at the different information displays in public transportation systems across Germany. Apart from Deutsche Bahn, most of them seemed outdated and provided more hints on 'what not to do.' I then brainstormed what information a person using public transportation would like to know (e.g., current time, time until stop, next stops, time until the next connection at the stop, etc.). Knowing what I wanted to display, I arranged the information logically. In essence, I created three screens: The first one shows general information until the next stop, the second one shows information about connections at the next stop, and the third one gives a rough overview of where the bus is and where it will go.",
                },
                {
                    title: "Designs explained",
                    content: "When it comes to the information displayed, it can all be ranked by priority. Most importantly, you want to check if you are on the right bus (info: bus number and destination). Secondly, you want to know what the next station(s) is/are. After that, you can display the approximate time until the station and provide a hint about accessibility (e.g., wheelchair-friendly) or other forms of public transportation available there. Based on this, I emphasized the more important information by placing it in boxes or using a larger, bolder font.",
                },
                {
                    title: "New Designs",
                    images: [
                        { image: img40, subtitle: "Information while driving"},
                        { image: img41, subtitle: "Visual feedback after 'Stop' was pressed"},
                        { image: img42, subtitle: "Information when approaching the station"},
                        { image: img43, subtitle: "Information about rough location on route"},
                    ],
                },
                {
                    title: "Further ideas",
                    content: "As Aachen is located close to Belgium, many people speak different languages in the city. Therefore, my first idea was to make texts like Next stop frequently switch between French, English, and German.",
                },
            ]
        },
    ];

    return (
        <DataContext.Provider value={{ projects }}>
            {children}
        </DataContext.Provider>
    );
};

// Custom hook to use the context
export const useData = () => {
    return useContext(DataContext);
};