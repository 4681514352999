import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import "../pages/home.css";

export default function Imprint() {

    return(
        <div className="main">
            <Helmet>
                <title>Imprint</title>
            </Helmet>
            <div className="flex-col gap-3">
                <div className="flex-col gap-1 items-center">
                    <div>All contents of this website were created and designed by Niclas Reif.</div>
                    <div>Below is a list of assets that I used to improve the illustration of the content.</div>
                </div>
                <div className="flex-col gap-1">
                    <div className="large font-semibold">Icons</div>
                    <div className="flex-col gap-1">
                        <a className="linkRow" href="https://www.flaticon.com/de/kostenlose-icons/deutschland" target="_blank" rel="noopener noreferrer">Germany flag <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/de/kostenloses-icon/spanien_330557?term=spanien&page=1&position=4&origin=search&related_id=330557">Spain flag <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/de/kostenloses-icon/vereinigtes-konigreich_555417?term=uk&page=1&position=5&origin=search&related_id=555417">UK flag <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/de/kostenloses-icon/frankreich_330490?term=frankreich+flagge&page=1&position=3&origin=search&related_id=330490">France flag <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/de/kostenloses-icon/css-3_732190?term=css&page=1&position=2&origin=search&related_id=732190">CSS icon <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/de/kostenloses-icon/python_5968350?term=python&page=1&position=4&origin=search&related_id=5968350">Python icon <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/de/kostenloses-icon/javascript_919828?term=javascript&page=1&position=1&origin=search&related_id=919828">JavaScript icon <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/de/kostenloses-icon/figma_5968705?term=figma+logo&page=1&position=1&origin=search&related_id=5968705">Figma icon <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/de/kostenloses-icon/kreative-wolke_731965?term=adobe&page=1&position=7&origin=search&related_id=731965">Adobe icon <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/de/kostenloses-icon/excel_732220?term=excel&page=1&position=1&origin=search&related_id=732220">Excel icon <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/de/kostenloses-icon/power-point_888874?term=powerpoint&page=1&position=3&origin=search&related_id=888874">Powerpoint icon <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.flaticon.com/de/kostenloses-icon/wort_888883?term=word&page=1&position=2&origin=search&related_id=888883">Word icon <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.blender.org/about/logo/" >Blender icon <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://fontawesome.com/">+ other icons <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                    </div>
                </div>
                <div className="flex-col gap-1">
                    <div className="large font-semibold">Images</div>
                    <div className="flex-col gap-1">
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://rfkhumanrights.org/person/muhammad-yunus-2/" >Muhammad Yunus <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.rocketfm.org.uk/norman-foster/">Norman Foster <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://sfg.media/en/magazine/the-problem-is-the-only-clear-thing-at-the-beginning-of-work-jony-ive/">Jony Ive <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.dazeddigital.com/music/article/46480/1/frank-ocean-defends-his-prep-hiv-new-york-club-night-against-critics" >Frank Ocean <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://t3n.de/news/steve-jobs-bewerbung-vesteigerung-chatterfields-1358200/">Steve Jobs <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.amazon.de/Michael-Jordan-6-rings-English-ebook/dp/B00YBAT4A2" >Michael Jordan <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.bloomberg.com/news/features/2021-09-15/peter-thiel-gamed-silicon-valley-tech-trump-taxes-and-politics" >Peter Thiel <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://artinterview.com/en/interviews/james-turrell/">James Turrell <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.guggenheim-bilbao.eus/en/the-collection/artists/jean-michel-basquiat">Jean-Michel Basquiat <div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.espn.com/nfl/story/_/page/greatestcoach2/greatest-coaches-nfl-history-bill-walsh">Bill Walsh<div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.cnbc.com/2019/08/23/lakers-trainer-gary-vitti-on-what-kobe-bryant-taught-him.html">Kobe Bryant<div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.baunetz-campus.de/news/viva-viktoria-entwuerfe-fuer-die-nachnutzung-des-viktoriabads-8238841">Current Look - Viktoriabad<div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                        <a className="linkRow" target="_blank" rel="noopener noreferrer" href="https://www.aachen.de/DE/stadt_buerger/verkehr_strasse/verkehrssicherheit/01_Aktionsplan_Verkehrssicherheit/03_Oeffentlichkeitsarbeit/20190601_ASEAG-Fahrgastmonitore.html">Current Info Display - Aachen<div className="linkText">Link <FontAwesomeIcon className="small" icon={faChevronRight}/></div></a>
                    </div>
                </div>
            </div>
        </div>
    )
}