import React, { useState, useEffect } from 'react';
import './Modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const Modal = ({ isOpen, onClose, images, initialImage }) => {
  const [selectedImage, setSelectedImage] = useState(initialImage); // Initialize with the passed initial image
  const [isFirstOpen, setIsFirstOpen] = useState(true); // Flag to track if it's the first time the modal is open

  // When the modal opens, reset the flag and keep the initial image
  useEffect(() => {
    if (isOpen) {
      setSelectedImage(initialImage); // Ensure the initial image is selected when opening
      setIsFirstOpen(true); // Mark it as the first open
    }
  }, [isOpen, initialImage]);

  if (!isOpen) return null;

  // Handle click on a thumbnail to select the image
  const handleThumbnailClick = (index) => {
    setSelectedImage(images[index].image); // Update selected image when a thumbnail is clicked
  };

  // Handle scroll event on the thumbnail preview section
  const handleThumbnailScroll = (e) => {
    if (isFirstOpen) {
      setIsFirstOpen(false); // Prevent the scroll logic from affecting the image the first time the modal opens
      return;
    }

    const thumbnails = e.target.children;
    let closest = Number.MAX_VALUE;
    let selectedThumbnailIndex = 0;

    // Loop through thumbnails to find the closest one to the center of the viewport
    Array.from(thumbnails).forEach((thumbnail, index) => {
      const rect = thumbnail.getBoundingClientRect();
      const distance = Math.abs(rect.left + rect.width / 2 - window.innerWidth / 2);
      if (distance < closest) {
        closest = distance;
        selectedThumbnailIndex = index;
      }
    });

    setSelectedImage(images[selectedThumbnailIndex].image); // Update the selected image based on scroll position
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {/* Display the selected large image */}
        <img src={selectedImage} alt="Enlarged" className="modal-image" />
        
        {/* Thumbnail previews with scroll behavior, only show if there are multiple images */}
        {images.length > 1 && (
          <div
            className="thumbnail-preview"
            onScroll={handleThumbnailScroll} // Handle scroll event on thumbnail preview
          >
            {images.map((image, index) => (
              <img
                key={index}
                src={image.image}
                alt={`Thumbnail ${index}`}
                className={`thumbnail ${image.image === selectedImage ? 'selected' : ''}`}
                onClick={() => handleThumbnailClick(index)} // Set selected image on thumbnail click
              />
            ))}
          </div>
        )}
      </div>
      
      {/* Close button */}
      <button className="close-button" onClick={onClose}>
        <FontAwesomeIcon icon={faClose} />
      </button>
    </div>
  );
};

export default Modal;