import { Helmet } from "react-helmet";
import niclasIcon from "../images/favicon_niclas.png";

export default function About() {

    return(
        <div className="about-main">
            <Helmet>
                <title>About Niclas</title>
            </Helmet>
            <div className="paragraph">My name is Niclas Reif, I am 23 years old, and I grew up in a family of six in Bonn, a city in western Germany. I spent most of my youth outdoors playing sports, especially field hockey. At 12, I was selected to play for the top regional team in the country for the next four years. When it came time to choose my high school, I opted for one that focused on languages, exchange programs, and other extracurricular activities, which I later took advantage of.</div>
            <div className="paragraph">At 14, I had the unique opportunity to attend high school in Auckland, New Zealand, for 10 months. That time was among the best in my life. I tried various new subjects and sports and continued playing competitive hockey for the region of Auckland. After returning to Germany, I enrolled in a government-supported school exchange with an Israeli high school, whose goal was to strengthen the bond between Israeli and German youth. During my final two years in high school, I participated in a one-year BCG school initiative aimed at introducing students to the world of economics through a variety of tasks.</div>
            <div className="paragraph">This experience inspired me to apply to ESCP, a European business school. To gain further experience in startups and economics, I joined two startups and completed two internships in Tel Aviv before starting my studies. During this time, I also developed the idea for ELGIO, an event platform that offers services and products to help people browse, organize, sell, and promote events. Since then, I have been working on ELGIO and have taught myself all aspects of startups through it.</div>
            <div className="paragraph">Through ESCP's unique program, I had the chance to study economics and management while experiencing the cultural variety of Europe by living in various countries. I therefore lived in London, Vienna, Madrid, and Berlin, all of which amazed me and reinforced my passion for international environments. In my final year of university, I also joined a Berlin-based PR consulting agency, where I handled all accounting and operational tasks alongside a coworker.</div>
            <div className="paragraph">In April 2024, I moved in with my two coworkers from ELGIO to focus entirely on the company and finally work in the same location after years of remote work. Additionally, I began volunteering as a teacher at a youth center, helping people earn their high school diplomas.</div>
            <div className="paragraph">In recent years, I’ve discovered that creating and improving are what I enjoy most. I love the process of taking an idea or identifying a problem and transforming it into a solution or product. Whenever I encounter something that doesn’t work as expected, I’m instantly flooded with ideas on how to improve or fix it—whether it’s the design of a subway ad, the customer flow in a supermarket, the use of an empty room, or a company’s business model.</div>
            <div className="paragraph">I would describe myself as a curious, creative, and energetic person. My innate curiosity drives me to explore and learn about everything, which is why I enjoy connecting with new people. I’m inspired by those who are deeply passionate about their work and strive to be the best at it. As Howard Thurman famously said, "Don't ask what the world needs. Ask what makes you come alive, and go do it. Because what the world needs is people who have come alive."</div>
            <img src={niclasIcon} alt="" style={{width: "50px"}}/>
        </div>
    );
}